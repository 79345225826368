html {
  background-color: #282c34;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Color editor */
#editor {
  /* padding-bottom: 50px; */
  /* max-width: 657px; */
}

#editorSwitcher {
  margin-bottom: 20px;
}

#buffer-submit {
  margin: 10px;
}

.quill {
  /* height: 80vh; */
}

.line-dotted {
  border-style: dotted;
}

.line-solid {
  border-style: solid;
}

.line-double {
  border-style: double;
}

.ql-editor {
  overflow-y: hidden;
  /* word-wrap:break-word; */
  /* word-break:break-all; */
}

.ql-container {
  overflow-x: auto;
}

.ql-toolbar.ql-snow {
  background-color: #000000;  
}

.ql-container.ql-snow {
  background-color: #000000;
  color: #C0C0C0;
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
  min-height: 70vh;
}

.ql-snow .ql-color-picker .ql-picker-options {
  width: 133px;
}

#ql-picker-options-0 {
  counter-reset: color-number;
  counter-set: color-number -1;
  width: 133px;
}

#ql-picker-options-0 > span {
  font-size: 8px;
}

#ql-picker-options-0 > span:after {
  counter-increment: color-number;
  content: counter(color-number);
  background: inherit;
  background-clip: text;
  -webkit-background-clip: text;
  filter: invert(1) contrast(1);
  mix-blend-mode: difference;
}

#ql-picker-options-0 > span:nth-child(16) {
  margin-bottom: 20px;
  margin-right: 32px;
  
}

#colorPickerContainer {
  display: flex;
  flex-direction: column;
}

#colorTextEditorContainer {
  padding-bottom: 20px;
}

#colorPreview {
  height: 50px;
  width: 294px;
  padding-top: 10px;
  display: flex;
}

#colorPreviewReal {
  display: inline-block;
  width: 20%;
  height: 100%;
  border-radius: 15px 0px 0px 15px;
}

#colorPreviewLotj {
  display: inline-block;
  width: 80%;
  height: 100%;
  border-radius: 0px 15px 15px 0px;
}

#rbgcp-wrapper > div:nth-last-child(2) {
  visibility: hidden;
  margin-bottom: -30px;
}

div#rbgcp-wrapper > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {
  visibility: hidden;
}

#pickerLotjNumberInput {
  padding-left: 10px;
  -moz-appearance: textfield;
  background: inherit;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  filter: invert(1);
  -webkit-filter: invert(1);
  font-size: calc(8px + 2vmin);
  font-weight: bolder;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
  appearance: none;
  border: none;
  height: 100%;
  width: 30%;
}

#pickerLotjNumberInput:focus-visible {
  outline: none;
}

#pickerLotjNumberInput::-webkit-inner-spin-button,
#pickerLotjNumberInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#currentColors {
  margin-top: 10px;
  flex-grow: 1;
  width: 294px;
}

.currentColorSquare {
  width: 45px;
  height: 45px;
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 20%;
}

#currentColorsContainer {
  height: 235px;
  overflow-y: auto;
}

/* Simple color editor */
#SimpleEditorContainer {
  background-color: black;
  color: #C0C0C0;
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
}

.taInput {
  height: 250px;
  background: #000000;
  border-color: #0275d8;
  color: #C0C0C0;
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
  float: left;
}

.taDisplay {
  background: #000000;
  color: #C0C0C0;
  padding-left: 25px;
  float: left;
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;

}
